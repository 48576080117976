import React, { useState, useEffect, useRef } from 'react';
import {Box, Button, Container, Stack, TextField, Typography} from "@mui/material";

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [status, setStatus] = useState('Disconnected');
    const webSocketRef = useRef(null)

    // Connect to WebSocket
    const connectWebSocket = () => {
        console.log("++++ Start connecting")
        try {
            // Close any existing connection
            if (webSocketRef.current) {
                webSocketRef.current.close();
            }

            // Create new WebSocket connection
            const socket = new WebSocket('ws://localhost:7932')
            webSocketRef.current = socket;

            // Connection opened
            socket.addEventListener('open', (event) => {
                setStatus('Connected');
                console.log("++++ Connected")
            });

            // Listen for messages
            socket.addEventListener('message', (event) => {
                const message = event.data;
                setMessages(prev => [...prev, { type: 'received', text: message }]);
            });

            // Connection closed
            socket.addEventListener('close', (event) => {
                setStatus('Disconnected');
            });

            // Error handling
            socket.addEventListener('error', (event) => {
                setStatus('Error');
                console.error('WebSocket error:', event);
            });

        } catch (error) {
            console.error('Failed to connect to WebSocket server:', error);
            setStatus('Error');
        }
    };

    // Send message
    const sendMessage = () => {
        console.log("++++ Sending message: " + inputMessage);
        if (!webSocketRef.current || webSocketRef.current.readyState !== WebSocket.OPEN) {
            setMessages(prev => [...prev, { type: 'error', text: 'Not connected to server' }]);
            return;
        }

        if (inputMessage.trim() === '') return;

        webSocketRef.current.send(inputMessage);
        setMessages(prev => [...prev, { type: 'sent', text: inputMessage }]);
        setInputMessage('');
    };

    // Disconnect WebSocket
    const disconnectWebSocket = () => {
        console.log("++++ Disconnecting")
        if (webSocketRef.current) {
            webSocketRef.current.close();
            webSocketRef.current = null;
        }
    };

    // Clean up WebSocket on component unmount
    useEffect(() => {
        if (!webSocketRef.current) {
            connectWebSocket()
        }
        return () => {
            disconnectWebSocket()
        };
    }, [])

    const content = (
        <Container
            maxWidth="sm"
            sx={{
                height: '70vh', // Full height of the viewport
                display: 'flex',
                flexDirection: 'column', // Stack items vertically
                justifyContent: 'space-between', // Pushes items to top and bottom
                alignItems: 'center'
            }}
        >
            {/* Title at the top */}
            <Typography variant="h4" gutterBottom>
                Chat Application
            </Typography>

            {/* Space for displaying messages */}
            <Box
                sx={{
                    flexGrow: 1, // Take up remaining space
                    overflowY: 'auto', // Scrolls if messages exceed the space
                    marginBottom: 2, // Add space at the bottom to prevent text input from overlapping
                }}
            >
                {messages.map((msg, index) => (
                    <Typography key={index} sx={{ marginBottom: 1 }}>
                        {msg.text}
                    </Typography>
                ))}
            </Box>

            {/* Input and Button at the bottom */}
            <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                <TextField
                    label="Type a message"
                    variant="outlined"
                    fullWidth
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={(e) => {
                        console.log("++++ SENDING >..")
                        if (e.key === 'Enter') sendMessage()
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={sendMessage}
                >
                    Send
                </Button>
            </Stack>
        </Container>
    )

    return content
}

export default Chat