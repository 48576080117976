import React, {useState} from 'react'
import {Autocomplete, Box} from '@mui/material'
import { TextField, Chip } from '@mui/material'
import {getTags} from "../utils/ServerAPI"
import {useNavigate} from "react-router-dom"

const TagsSelection = ({acceptAny, initialTags, onTagsChange}) => {
    const navigate = useNavigate()
    const [tags, setTags] = useState(initialTags || [])
    const [suggestions, setSuggestions] = useState([])
    const [inputValue, setInputValue] = useState('')

    const fetchSuggestions = async (prefix) => {
        if (!prefix) {
            setSuggestions([])
            return
        }

        getTags(prefix, function(error, data) {
            if (error) {
                console.error("Failed to get tags: " + JSON.stringify(error))
                if (error.data && error.data.code === "401") {
                    navigate("/signin")
                }
            } else {
                const tagsArray = data.data.tags.map((item) => item.name)
                setSuggestions(tagsArray)
            }
        })
    }

    const handleTagsChange = (event, newValue) => {
        setTags(newValue)
        onTagsChange(newValue)
    }

    const handleInputChange = (event, newInputValue) => {
        if (newInputValue.endsWith(' ')) {
            const trimmedTag = newInputValue.trim()
            if (trimmedTag) {
                setTags((prevTags) => [...prevTags, trimmedTag])
            }
            setInputValue('')
        } else {
            setInputValue(newInputValue)
            fetchSuggestions(newInputValue)
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', paddingTop: '10px', width: '100%'}}>
            <Autocomplete
                multiple
                id="tags-filled"
                options={suggestions}
                freeSolo={acceptAny}
                style={{paddingBottom: '20px'}}
                value={tags}
                onChange={handleTagsChange}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({index})} />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Select topics"
                        placeholder="Start typing..."
                    />
                )}
            />

            {tags.length > 0 &&
                <Box sx={{padding: '8px', marginBottom: '16px', border: '1px solid #ddd'}}>
                    {tags.map((tag, index) => (
                        <Chip key={index} label={tag} style={{margin: 2}}/>
                    ))}
                </Box>
            }
        </div>
    )
}

export default TagsSelection
