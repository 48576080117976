import React from "react"
import DrawerMenu from "./DrawerMenu"
import {BrowserRouter, useRoutes} from "react-router-dom"
import Challenges from "./Challenges"
import Profile from "./Profile"
import Layout from "./Layout"
import SignIn from "./components/auth/SignIn"
import EditChallenge from "./EditChallenge"
import SignUp from "./components/auth/SignUp"
import ShowChallenge from "./ShowChallenge"
import Quiz from "./Quiz"
import ProtectedRoute from "./components/ProtectedRoute"
import ForgotPassword from "./ForgotPassword"
import FetchChallenges from "./FetchChallenges"
import Chat from "./Chat"
import ChatBkp from "./ChatBkp";

const AppRoutes = () => {
    return useRoutes([
        {path: '/', element: <Quiz/>},
        {path: '/signin', element: <SignIn/>},
        {path: '/signup', element: <SignUp/>},
        {path: '/forgot-password', element: <ForgotPassword/>},
        {path: '/profile', element: <Profile/>},
        {path: '/chat', element: <Chat/>},
        {path: '/add-challenge', element: <EditChallenge/>},
        {path: '/show-challenge/:challenge', element: <ShowChallenge/>},
        {path: '/edit-challenge/:challenge', element: <EditChallenge/>},
        {path: '/fetch-challenges', element: <FetchChallenges/>},
        {path: '/challenges', element: <Challenges/>},
    ])
}

const App = () => {
    return (
        <BrowserRouter>
        <div>
            <DrawerMenu />
            <Layout>
                <ProtectedRoute>
                    <AppRoutes />
                </ProtectedRoute>
            </Layout>
        </div>
        </BrowserRouter>
    )
}

export default App
